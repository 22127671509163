export default class Test {
  id = null;
  name = null;
  time = null;
  month = null;
  /** 构造方法 */
  constructor(obj) {
    this.month = obj;
    this.init(obj);
  }
  init(obj) {
    let monthInDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let month = obj;
    let year = new Date().getFullYear();
    let calendarBodyList = [];
    calendarBodyList.splice(0);
    month = Number(month);
    // 上月
    let firstMonth = month - 1 < 0 ? 11 : month - 1;
    // 下月
    let lastMonth = month + 1 > 11 ? 0 : month + 1;
    // 这个月的第一天是周几
    let onMonday = new Date(year, month, 1).getDay();
    // 闰年判断
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      monthInDay[1] = 29;
    }

    // 取上个月的后几天填充
    if (onMonday > 0) {
      let firstDays = monthInDay[firstMonth];
      let firstArr = [];
      
      for (let i = 0; i < firstDays; i++) {
        firstArr.push({
          month: firstMonth,
          day: i,
          year: firstMonth === 11 ? year - 1 : year,
          zhou:new Date(year, month, (i+1)).getDay()
        });
      }
      let firstSplit = firstArr.splice(firstArr.length - onMonday);
      calendarBodyList.unshift(...firstSplit);
    }
    // 填充当月时间
    for (let i = 0; i < monthInDay[month]; i++) {
      calendarBodyList.push({
        month: month,
        day: i,
        year,
        zhou:new Date(year, month, (i+1)).getDay()
      });
    }
    let nextNum = monthInDay[lastMonth];
    // let nextNum = monthInDay[lastMonth];
    // if (calendarBodyList.length > 35) {
    //   nextNum = 42 - calendarBodyList.length;
    // } else {
    //   nextNum = 35 - calendarBodyList.length;
    // }
    // 获取下个月的前几天填充
    for (let i = 0; i < nextNum; i++) {
      calendarBodyList.push({
        month: lastMonth,
        day: i,
        zhou:new Date(year, month, (i+1)).getDay(),
        year: lastMonth === 0 ? year + 1 : year,
      });
    }
    calendarBodyList.forEach((v) => {
      v.month += 1;
      v.day += 1;
    });
    this.time = calendarBodyList;
  }
  getTime() {
    return this.time;
  }
  getSplice(day) {
    let list = [];
    let i = "";
    this.time.forEach((item, index) => {
      if (item.day == day) {
        i = index;
      }
    })
    this.time.forEach((item, index) => {
      if (index <= (i + 2) && index >= (i - 2)) {
        list.push(item)
      }
    })
    return list;
  }
  static staticMethod() {
    console.log('静态方法，不用实例化就可以调用了')
  }
}
