<template>
  <div class="MyCalendar">
    <div class="headdom">
      <div class="flex" style="height: 100%">
        <div
          :class="`flexcolumn${myDay == item.day ? ' action' : ''} `"
          style="justify-content: center"
          v-for="(item, index) in list"
          :key="index"
          @click="btn(item)"
        >
          <div>周{{ Alb(item.zhou) }}</div>
          <h1 style="font-weight: bold; font-size: 10px">
            {{ item.month }}月{{ item.day }}
          </h1>
          <!-- <div>{{ item.month }}月</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Time from "@/utils/Calendar";
export default {
  name: "MyCalendar",
  components: {},
  data() {
    return {
      list: [],
      obj: [],
      myDay: new Date().getDate(),
    };
  },
  computed: {
    Alb() {
      return function (i) {
        switch (i) {
          case 1:
            return "一";
            break;
          case 2:
            return "二";
            break;
          case 3:
            return "三";
            break;
          case 4:
            return "四";
            break;
          case 5:
            return "五";
            break;
          case 6:
            return "六";
            break;
          case 0:
            return "日";
            break;
          default:
            return i;
            break;
        }
      };
    },
  },
  watch: {},
  methods: {
    init(t, day) {
      if (day) {
        this.obj = new Time(t).getTime();
        this.obj.filter((item, index) => {
          if ((item.day == day && item.month == t + 1) || item.month == t) {
            this.list = this.obj
              .slice(index - 2, index)
              .concat(this.obj.slice(index, index + 3));
          }
        });
      } else {
        this.list = new Time(t).getTime();
      }
    },
    btn(val) {
      this.myDay = val.day;
      this.$emit("calendarItem",val)
      this.init(val.month - 1, val.day);
    },
  },
  created() {
    let date = new Date();
    let newTime = date.getMonth() - 1 < 0 ? 0 : date.getMonth();
    this.init(newTime, date.getDate());
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.headdom {
  height: 150px;
  width: 100%;
  background-color: white;
  .flexcolumn {
    min-width: 13%;
  }
}
.action {
  height: 90%;
  padding: 0 15px;
  background-color: #03a9f4;
  border-radius: 100px;
  color: white;
  position: relative;
  &::after {
    content: "";
    position: relative;
    width: 14px;
    bottom: -10px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
  }
}
.myDiv {
  margin-bottom: 20px;
  span {
    display: block;
    width: 26px;
    height: 26px;
    background-color: white;
    border: 6px solid #03a9f4;
    border-radius: 50%;
  }
  h1 {
    font-size: 30px;
    margin-left: 10px;
    font-weight: bold;
  }
}
</style>