<template>
  <div class="Warehouse">
    <div style="height: 45px"></div>
    <div class="head">
      <van-nav-bar
        title="帮助中心"
        style="background-color: #fbfbfb"
        fixed
        :left-arrow="true"
        @click-left="onClickLeft"
      />
    </div>
    <!-- <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      style="margin-top: 20px"
    > -->
      <van-cell-group inset>
        <!-- <van-cell
          icon="hotel-o"
          :title="item.hpdc03"
          is-link
          v-for="(item, index) in contontList"
          :key="index"
          @click="change(item)"
        /> -->

        <van-collapse v-model="activeNames">
          <van-collapse-item
            :title="item.hpdc03"
            :name="item.hpdc01"
            v-for="(item, index) in contontList"
            :key="index"
          >
            <div style="padding: 20px 40px" @click="change(item)">
              点击此处查看详情内容...
            </div>
            <SubMenu :key="item.hpdc01" :menu="item" />
          </van-collapse-item>
        </van-collapse>
      </van-cell-group>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import { GetControlData, Findhelpdoc } from "@/api";
import MyCalendar from "@/components/Calendar";
export default {
  name: "MyAdministration",
  components: {
    MyCalendar,
    SubMenu: () => import("./components/index.vue"),
  },
  data() {
    return {
      isLoading: false,
      isMenu: false,
      contontList: [],
      activeNames: [0],
      ContInfo: {},
    };
  },
  computed: {},
  watch: {},
  methods: {
    aa() {
      console.log(111);
    },
    deleteBtn(item) {
      console.log(item);
    },
    onRefresh() {
      setTimeout(() => {
        this.GetControlData();
        // this.$Toast("刷新成功");
        this.isLoading = false;
      }, 600);
    },
    async GetControlData() {
      let params = {
        type: 5,
      };
      let res = await GetControlData({ params });
      if (res.Code > 0) {
        res.Data.forEach((el) => {
          el["children"] = [];
          res.Data.forEach((vl) => {
            if (el.hpdc01 == vl.hpdc02) {
              el.children.push(vl);
            }
          });
        });
        this.contontList = res.Data.filter((e) => e.hpdc02 == 0);
      }
    },
    change(data) {
      let params = {
        helpocid: data.hpdc01,
      };
      Findhelpdoc({ params }).then((res) => {
        if (res.Code > 0) {
          this.ContInfo = res.Data;
          this.$Dialog
            .alert({
              title: this.ContInfo.hpdc03,
              message: this.ContInfo.hpdc06,
            })
            .then(() => {});
        }
      });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    getTime(val) {
      console.log(val);
    },
  },
  created() {
    this.GetControlData();
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.Warehouse {
  h1 {
    font-size: 30px;
    font-weight: bold;
  }
  .content {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 240px);
    .myDiv {
      margin-bottom: 20px;
    }
    .statusC {
      display: block;
      padding: 2px 40px;
      color: red;
      border: 1px solid red;
      border-radius: 6px;
    }
    .statusR {
      display: block;
      padding: 2px 40px;
      color: #03a9f4;
      border: 1px solid #03a9f4;
      border-radius: 6px;
    }
    .title {
      font-size: 32px;
      margin-right: 40px;
      font-weight: bold;
    }
    .flootDiv {
      width: 90%;
      background-color: white;
      text-align: center;
      border-radius: 8px;
      margin: 4px;
      line-height: 80px;
      box-shadow: 0 0 5px 4px #efefef;
      position: fixed;
      z-index: 0;
      bottom: 110px;
      p {
        height: 80px;
        i {
          padding: 10px;
          border-bottom: 1px solid #d2d2d2;
        }
      }
    }
  }
}
</style>